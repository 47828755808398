import "air-datepicker/dist/js/i18n/datepicker.cs.js";
import "air-datepicker/dist/js/i18n/datepicker.da.js";
import "air-datepicker/dist/js/i18n/datepicker.de.js";
import "air-datepicker/dist/js/i18n/datepicker.en.js";
import "air-datepicker/dist/js/i18n/datepicker.es.js";
import "air-datepicker/dist/js/i18n/datepicker.fi.js";
import "air-datepicker/dist/js/i18n/datepicker.fr.js";
import "air-datepicker/dist/js/i18n/datepicker.hu.js";
import "air-datepicker/dist/js/i18n/datepicker.nl.js";
import "air-datepicker/dist/js/i18n/datepicker.pl.js";
import "air-datepicker/dist/js/i18n/datepicker.pt-BR.js";
import "air-datepicker/dist/js/i18n/datepicker.pt.js";
import "air-datepicker/dist/js/i18n/datepicker.ro.js";
import "air-datepicker/dist/js/i18n/datepicker.sk.js";
import "air-datepicker/dist/js/i18n/datepicker.zh.js";

(function ($) {
	$.fn.datepicker.language["ar"] = {
		days: ["الأحد", "الأثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعه", "السبت"],
		daysShort: ["الأحد", "الأثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعه", "السبت"],
		daysMin: ["الأحد", "الأثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعه", "السبت"],
		months: [
			"يناير",
			"فبراير",
			"مارس",
			"أبريل",
			"مايو",
			"يونيو",
			"يوليو",
			"أغسطس",
			"سبتمبر",
			"اكتوبر",
			"نوفمبر",
			"ديسمبر",
		],
		monthsShort: [
			"يناير",
			"فبراير",
			"مارس",
			"أبريل",
			"مايو",
			"يونيو",
			"يوليو",
			"أغسطس",
			"سبتمبر",
			"اكتوبر",
			"نوفمبر",
			"ديسمبر",
		],
		today: "اليوم",
		clear: "حذف",
		dateFormat: "dd/mm/yyyy",
		timeFormat: "hh:ii aa",
		firstDay: 6,
	};
})(jQuery);

(function ($) {
	$.fn.datepicker.language["gr"] = {
		days: ["Κυριακή", "Δευτέρα", "Τρίτη", "Τετάρτη", "Πέμπτη", "Παρασκευή", "Σάββατο"],
		daysShort: ["Κυρ", "Δευ", "Τρι", "Τετ", "Πεμ", "Παρ", "Σαβ"],
		daysMin: ["Κυ", "Δε", "Τρ", "Τε", "Πε", "Πα", "Σα"],
		months: [
			"Ιανουάριος",
			"Φεβρουάριος",
			"Μάρτιος",
			"Απρίλιος",
			"Μάιος",
			"Ιούνιος",
			"Ιούλιος",
			"Αύγουστος",
			"Σεπτέμβριος",
			"Οκτώβριος",
			"Νοέμβριος",
			"Δεκέμβριος",
		],
		monthsShort: [
			"Ιαν",
			"Φεβ",
			"Μαρ",
			"Απρ",
			"Μάι",
			"Ι/ν",
			"Ι/λ",
			"Αυγ",
			"Σεπ",
			"Οκτ",
			"Νοε",
			"Δεκ",
		],
		today: "Σήμερα",
		clear: "Καθαρισμός",
		dateFormat: "dd/mm/yyyy",
		timeFormat: "hh:ii aa",
		firstDay: 0,
	};
})(jQuery);

(function ($) {
	$.fn.datepicker.language["it"] = {
		days: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
		daysShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
		daysMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
		months: [
			"Gennaio",
			"Febbraio",
			"Marzo",
			"Aprile",
			"Maggio",
			"Giugno",
			"Luglio",
			"Agosto",
			"Settembre",
			"Ottobre",
			"Novembre",
			"Dicembre",
		],
		monthsShort: [
			"Gen",
			"Feb",
			"Mar",
			"Apr",
			"Mag",
			"Giu",
			"Lug",
			"Ago",
			"Set",
			"Ott",
			"Nov",
			"Dic",
		],
		today: "Oggi",
		clear: "Reset",
		dateFormat: "dd/mm/yyyy",
		timeFormat: "hh:ii",
		firstDay: 1,
	};
})(jQuery);

(function ($) {
	$.fn.datepicker.language["tr"] = {
		days: ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"],
		daysShort: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"],
		daysMin: ["Pz", "Pt", "Sa", "Ça", "Pe", "Cu", "Ct"],
		months: [
			"Ocak",
			"Şubat",
			"Mart",
			"Nisan",
			"Mayıs",
			"Haziran",
			"Temmuz",
			"Ağustos",
			"Eylül",
			"Ekim",
			"Kasım",
			"Aralık",
		],
		monthsShort: [
			"Oca",
			"Şub",
			"Mar",
			"Nis",
			"May",
			"Haz",
			"Tem",
			"Ağu",
			"Eyl",
			"Eki",
			"Kas",
			"Ara",
		],
		today: "Bugün",
		clear: "Temizle",
		dateFormat: "dd.mm.yyyy",
		timeFormat: "hh:ii",
		firstDay: 1,
	};
})(jQuery);
